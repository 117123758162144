import React, { useEffect, useState } from 'react';
import {
  WarningFilled, FacebookFilled, InstagramFilled, TwitterOutlined, YoutubeFilled,
} from '@ant-design/icons';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { StorageInterface } from '../../../../../store/StorageInterface';
import { ReactComponent as SadCarOutlined } from '../../../../../resources/img/auto-triste-fondo-off.svg';
import { IFooterComponent } from './interfaces/FooterComponent.interfaces';
import './scss/FooterComponent.scss';
import { ReactComponent as LogoAutofact } from '../../../../../resources/img/af-key-version-secundaria.svg';
import { ReactComponent as LogoAutofactSecondary } from '../../../../../resources/img/logo-autofact-version-secundaria.svg';
import AppHelper from '../../../../../helpers/digitalGoodsService';

export default function FooterComponent({
  autofactUrl = '/', facebookUrl = '', instagramUrl = '', twitterUrl = '', youtubeUrl = '', csrfTokenExpiration = 3600,
}: IFooterComponent): JSX.Element {
  const redirectUrl = `https://${autofactUrl}`;
  const [modal, setModal] = useState(false);
  const csrfTokenExpirationMinutes = csrfTokenExpiration / 60;

  const activeModal = () => setModal(true);
  useEffect(() => {
    setTimeout(() => {
      activeModal();
    }, csrfTokenExpiration * 1000);
  }, []);

  const headerSlice = useSelector(
    (state: StorageInterface) => state.headerSlice,
  );

  return (
    <>
      <footer className="bg-blue-dark  px-2 py-2 px-md-5 py-3 FooterComponent">
        <Modal isOpen={modal} centered>
          <ModalHeader className="text-center border-0 m-auto p-3">
            <SadCarOutlined className="car-outlined mb-3" />
            <WarningFilled className="text-yellow mb-3 font-size-34" />
            <h3>¡Precaución!</h3>
          </ModalHeader>
          <ModalBody className="text-center pt-0">
            Llevas inactivo más de&nbsp;
            {csrfTokenExpirationMinutes}
            &nbsp;minutos. Para continuar, por seguridad debes actualizar la página.
          </ModalBody>
          <ModalFooter className="border-0 m-auto modal-footer text-center p-3">
            <Button color="primary" href={redirectUrl} className="lh-lg">
              Actualizar
            </Button>
          </ModalFooter>
        </Modal>
        {
          headerSlice?.isRetailHost === true
            ? (
              <div className="">
                <div className="d-flex justify-content-center">
                  <a href={redirectUrl} id="logoFoot" data-testid="logoFoot" onClick={AppHelper.isInApk() ? (evt) => evt.preventDefault() : undefined}>
                    <LogoAutofact />
                  </a>
                </div>
              </div>
            )
            : (
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col px-0">
                    <a
                      href={redirectUrl}
                      id="logoFoot"
                      className="secondary-logo"
                      data-testid="logoFoot"
                      onClick={AppHelper.isInApk() ? (evt) => evt.preventDefault() : undefined}
                    >
                      <LogoAutofactSecondary
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="align-self-center col-auto rrss-links">
                    {instagramUrl !== '' && (
                    <a href={instagramUrl} className="" target="_blank" rel="noreferrer">
                      <InstagramFilled className="font-size-14 text-white" />
                    </a>
                    )}
                    {facebookUrl !== '' && (
                    <a href={facebookUrl} className="" target="_blank" rel="noreferrer">
                      <FacebookFilled className="font-size-14 text-white" />
                    </a>
                    )}
                    {twitterUrl !== '' && (
                    <a href={twitterUrl} className="" target="_blank" rel="noreferrer">
                      <TwitterOutlined className="font-size-14 text-white" />
                    </a>
                    )}
                    {youtubeUrl !== '' && (
                    <a href={youtubeUrl} className="" target="_blank" rel="noreferrer">
                      <YoutubeFilled className="font-size-14 text-white" />
                    </a>
                    )}

                  </div>
                </div>
                <div className="row text-center text-md-start">
                  <p className="px-0 font-size-12 text-blue-secondary line-height-1-7-5">© Todos los derechos reservados</p>
                </div>
              </div>
            )
        }
      </footer>
    </>
  );
}
